<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
      ref="table"
      :headers="headers"
      :items="items"
      :items-per-page.sync="perPage"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100, 500],
      }"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDirection"
      :page.sync="page"
      :server-items-length.sync="total"
      @update:options="getItems"
      class="elevation-1"
      style="width: 100%"
      :loading="spinnerActive"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ExitPool Surveys</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- VIEW DIALOG -->
          <v-dialog v-model="dialogView" max-width="1000px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Exit-Poll-Survey Questions</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      v-model="editedItem.name"
                      disabled
                      label="Name"
                    ></v-text-field>
                  </v-row>
                  <v-row
                    v-for="(question, i) in questions"
                    :key="'question_' + i"
                  >
                    <v-text-field
                      v-model="questions[i].title"
                      :label="`Question ${i + 1}`"
                    ></v-text-field>
                    <v-btn class="ml-8" v-on:click="removeQuestion(i)">
                      X
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn class="primary" v-on:click="newQuestion"
                      >ADD QUESTION</v-btn
                    >
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeView"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updateExitPollSurveyQuestions"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ADD/EDIT DIALOG -->
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{}">
              <v-btn color="primary" dark class="mb-2" v-on:click="addItem"
                >New Item</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-form
                ref="form"
                @submit.prevent="save()"
                v-model="valid"
                action="/"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="editedItem.name"
                        label="Name"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="editedItem.type"
                        label="Type"
                        disabled
                      ></v-text-field>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <!-- DELETE DIALOG -->
          <v-dialog v-model="dialogDelete" max-width="1000px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions2`]="{ item }">
        <v-switch v-model="item.is_activated" class="mr-2" v-on:click="activateDeactivateItem(item)"></v-switch>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)">mdi-help-network</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
        <v-icon small class="mr-2" @click="viewResults(item)">mdi-eye</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getItems">No data</v-btn>
      </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  fetchExitPollSurveys,
  addExitPollSurvey,
  editExitPollSurvey,
  deleteExitPollSurvey,
  updateExitPollSurveyQuestionsAPI,
  fetchExitPollSurveyQuestions,
  activateDeactivateExitPollSurvey
} from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "ExitPollSurveys",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "name",
      spinnerActive: false,
      dialog: false,
      dialogView: false,
      dialogDelete: false,
      headers: [
        { text: "ExitPollSurvey name", value: "name" },
        { text: "ExitPollSurvey type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "Active", value: "actions2", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: { name: "", type: "" },
      defaultItem: { name: "", type: "program-survey" },
      defaultQuestion: { title: "", id: "" },
      questions: [],
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    viewResults(item) {
      this.$router.push(`/survey-answers/${item.id}`)
    },
    async activateDeactivateItem(item) {
      await activateDeactivateExitPollSurvey(item.id).then(() => {
        this.getItems();
      })
    },
    async getItems() {
      if (this.spinnerActive) return;
      this.items = [];
      setTimeout(async () => {
        let tableData = {};
        tableData["perPage"] = this.perPage;
        tableData["sortBy"] = this.sortBy;
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC";
        tableData["page"] = this.page;
        this.spinnerActive = true;
        // console.log('table Pagination ===> ', this.$refs.table)
        await fetchExitPollSurveys(tableData)
          .then((res) => {
            if (res.data) {
              this.items = res.data.data.data;
              this.spinnerActive = false;
              this.total = res.data.data.total;
            }
          })
          .catch((error) => {
            console.log(error);
            this.spinnerActive = false;
          });
      }, 0);
    },
    async getExitPollSurveyQuestions() {
      await fetchExitPollSurveyQuestions(this.editedItem).then((response) => {
        this.questions = response.data.data.questions;
      });
    },
    newQuestion() {
      let defaultQuestion = Object.assign({}, this.defaultQuestion);
      this.questions.push(defaultQuestion);
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    viewItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogView = true;
      this.getExitPollSurveyQuestions();
    },
    addItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await deleteExitPollSurvey(this.editedItem)
        .then(() => {
          this.getItems();
          this.closeDelete();
        })
        .then((error) => {
          console.log("deleteExitPollSurveys error => ", error);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeView() {
      this.dialogView = false;
      this.questions = [];
    },
    async updateExitPollSurveyQuestions() {
      await updateExitPollSurveyQuestionsAPI(this.editedItem.id, this.questions)
        .then(() => {
          this.getItems();
          this.closeView();
        })
        .then((error) => {
          console.log("addExitPollSurvey error => ", error);
        });
    },
    async save() {
      let formValidated = this.$refs.form.validate();
      if (!formValidated) return;
      if (this.editedIndex > -1) {
        // EDIT
        await editExitPollSurvey(this.editedItem)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addExitPollSurvey error => ", error);
          });
      } else {
        // ADD
        await addExitPollSurvey(this.editedItem)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addExitPollSurvey error => ", error);
          });
      }
    },
  },
};
</script>
